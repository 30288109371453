'use strict';

/**
 * Validates and Return the cquotient namespace provided by the commerce cloud platform
 * @returns {Object} - einsteinUtils or null
 */
function getEinsteinUtils() {
    var einsteinUtils = window.CQuotient;
    if (einsteinUtils && (typeof einsteinUtils.getCQUserId === 'function') && (typeof einsteinUtils.getCQCookieId === 'function')) {
        return einsteinUtils;
    }
    return null;
}

/**
 * Processes a recommendation tile, with an already initialized category specific anchors array
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @param {Object} einsteinUtils cquotient object
 * @param {Array} anchorsArray array of objects representing anchors
 */
function processRecommendationsTile($parentElement, einsteinUtils) {
    var recommender = $parentElement.data('recommender');

    var params = {
        userId: einsteinUtils.getCQUserId(),
        cookieId: einsteinUtils.getCQCookieId(),
        ccver: '1.01'
    };

    /**
    * Processes a recommendation responses
    * @param {Object} einsteinResponse cquotient object
    */
    function recommendationsReceived(einsteinResponse) {
        fillDomElement(einsteinResponse, $parentElement);
        $parentElement.spinner().stop();
    }

    if (einsteinUtils.getRecs) {
        einsteinUtils.getRecs(einsteinUtils.clientId, recommender, params, recommendationsReceived);
    } else {
        einsteinUtils.widgets = einsteinUtils.widgets || []; // eslint-disable-line no-param-reassign
        einsteinUtils.widgets.push({
            recommenderName: recommender,
            parameters: params,
            callback: recommendationsReceived
        });
    }
}

/**
 * fills in the carousel with product tile html objects
 * @param {string} einsteinResponse string html for product tiles
 * @param {jQuery} $parentElement parent element where recommendations will show.
 */
function fillDomElement(einsteinResponse, $parentElement) {
    var Swiper = require('swiper');
    var recommender = $parentElement.data('recommender');
    var recommendedProducts = einsteinResponse[recommender].recs;
    if (recommendedProducts && recommendedProducts.length > 0) {
        var featuredProductCarousel = new Swiper('.homepage-featured-products-block-carousel', {
            zoom: false,
            slidesPerView: 4,
            loop: false,
            centeredSlides: false,
            keyboardControl: false,
            grabCursor: true,
            spaceBetween: 16,
            breakpoints: {
                584: {
                    slidesPerView: 2.1,
                    spaceBetween: 0
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 16
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 16
                }
            }
        });

        for (let i = 0; i < 4; i++) {
            $.ajax({
                url: $parentElement.data('url'),
                type: 'GET',
                dataType: 'json',
                data: {
                    productID: recommendedProducts[i].id,
                    backgroundColor: $parentElement.data('background-color')
                },
                success: function (data) {
                    if (data.renderedTemplate) {
                        featuredProductCarousel.appendSlide(data.renderedTemplate);
                    }
                },
                error: function () {
                    //do nothing
                }
            });
        }
    }

    $parentElement.spinner().stop();
}

/**
 * Gets all placeholder elements, which hold einstein recommendations queries the details from the
 * einstein engine and feeds them back to the dom element
 */
function loadRecommendations() {
    var einsteinUtils = getEinsteinUtils();
    if (einsteinUtils) {
        var $recommendationTiles = $('.homepage-featured-products-block-carousel');
        $recommendationTiles.each(function () {
            var $parentElement = $(this);
            $parentElement.spinner().start();
            return processRecommendationsTile($parentElement, einsteinUtils);
        });
    }
}

$(document).ready(function () {
    loadRecommendations();
});
